<template>
  <CAvatar v-if="authenticated" :src="user.picture" size="md" />
</template>

<script>
export default {
  name: 'AppHeaderDropdownAccnt',
}
</script>

<script setup>
import { useStore } from 'vuex'
//import { ref } from 'vue'
import { computed } from 'vue'

const store = useStore()

let authenticated = computed(() => {
  return store.getters.authenticated
})
let user = computed(() => {
  return store.state.user
})
</script>
