<template>
  <CHeader class="mb-4 bg-gradient bggrey" style="z-index: 20">
    <CContainer
      fluid
      class="menu-header d-xxl-none d-xxl-block d-xl-none d-lg-none"
    >
      <AppMainMenuResponsive />
    </CContainer>
    <CContainer
      fluid
      class="menu-header d-none d-xxl-block d-xl-block d-lg-block"
    >
      <AppMainMenu />
    </CContainer>
    <CContainer fluid class="p-0">
      <svg viewBox="0 0 1825 430">
        <path
          fill="#3b4054"
          fill-opacity="1"
          d="M0 0L1825 0L1825 530L0 530Z"
        ></path>
        <path
          fill="#2e3142"
          fill-opacity="1"
          d="M0 340C287 253 345 35 594 38 805 67 842 278 968 136L965 530 0 530Z"
        ></path>
        <path
          fill="#3b4054"
          fill-opacity="1"
          d="M0 400C196 393 178 251 334 221 411 207 547 287 642 292 891 345 802 54 1190 5 1359-12 1410 17 1599 42 1677 47 1729 74 1824 22L1825 530 0 530Z"
        ></path>
        <path
          fill="#4f5468"
          fill-opacity="1"
          d="M867 182C924 77 1032 20 1190 5 1359-12 1410 17 1599 42 1677 47 1729 74 1824 22L1825 530 988 163C951 205 901 195 867 182"
        ></path>
        <path
          fill="#dbdce1"
          fill-opacity="1"
          d="M0 400C208 382 147 280 337 259 513 251 499 329 747 391 925 420 789 66 1193 28 1342 28 1414 36 1734 113 1793 125 1808 104 1825 89L1825 530 0 530Z"
        ></path>
        <image
          href="@/assets/images/logo_claro.png"
          class="img-fluid"
          x="400"
          y="110"
          width="400"
          height="81"
          id="logo"
        />
        <text class="intro" x="1200" y="180" id="lemma1">Bienvenidos</text>
        <text class="intro" x="1110" y="240" id="lemma2">
          a una comunicación
        </text>
        <text class="cblue" x="1050" y="320" id="lemma3">
          LIBRE DE BARRERAS
        </text>
      </svg>
    </CContainer>
  </CHeader>
</template>

<script>
import AppMainMenu from '@/components/AppMainMenu.vue'
import AppMainMenuResponsive from '@/components/AppMainMenu2.vue'
export default {
  name: 'AppHeaderHome',
  components: {
    AppMainMenu,
    AppMainMenuResponsive,
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
.menu-header {
  background-color: $copla-blue-grey-light;
}
.menu-header a {
  color: white;
}
.header {
  padding: 0;
}
.bggrey {
  background-color: $copla-grey-light;
}
.bgblue-grey-dark {
  background-color: $copla-blue-grey-dark;
}
.cblue {
  color: $copla-blue;
  font-weight: bold;
  font-size: 3.5em;
  fill: $copla-blue;
}
.intro {
  font-size: 2.5em;
  font-family: Poppins;
}
@media only screen and (max-width: 1400px) {
  .intro {
    font-size: 2.7em;
  }
  .cblue {
    font-size: 3.7em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
}
@media only screen and (max-width: 991px) {
  .intro {
    font-size: 3em;
  }
  .cblue {
    font-size: 4em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
}
@media only screen and (max-width: 767px) {
  .intro {
    font-size: 3.5em;
  }
  .cblue {
    font-size: 4.5em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(10px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(20px);
  }
  #logo {
    width: 600px;
    transform: translateX(-200px) translateY(-50px);
  }
}
@media only screen and (max-width: 575px) {
  .intro {
    font-size: 4em;
  }
  .cblue {
    font-size: 5em;
  }
  #lemma1 {
    transform: translateX(-70px) translateY(0px);
  }
  #lemma2 {
    transform: translateX(-70px) translateY(15px);
  }
  #lemma3 {
    transform: translateX(-70px) translateY(30px);
  }
  #logo {
    width: 600px;
    transform: translateX(-200px) translateY(-50px);
  }
}
.backgroundSVG {
  background-image: url('~@/assets/images/header.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
