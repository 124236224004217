<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100">
      <app-header-home />
      <div class="body flex-grow-1 px-0" style="padding-top: 100px; z-index: 2">
        <CContainer lg>
          <CRow style="text-align: center">
            <CCol sm="4"
              ><img
                src="@/assets/images/artes.png"
                alt="Sección artes escénicas"
              />
              <h2 style="margin-top: 20px; margin-bottom: 20px">
                Artes escénicas
              </h2>
              <ul style="text-align: left">
                <li>
                  Rápida preparación de la accesibilidad para espectáculos.
                </li>
                <li>Sincronización automática de subtítulos en directo.</li>
                <li>Integración con guiones de regiduría.</li>
                <li>Gestión de múltiples entradas.</li>
                <li>
                  Envío de la accesibilidad a diferentes salidas y dispositivos.
                </li>
              </ul>
            </CCol>
            <CCol sm="4"
              ><img
                src="@/assets/images/audiovisuales.png"
                alt="Sección audiovisuales"
              />
              <h2 style="margin-top: 20px; margin-bottom: 20px">
                Audiovisuales
              </h2>
              <ul style="text-align: left">
                <li>Subtitulado automático.</li>
                <li>Transcripción automática temporizada.</li>
                <li>Traducción automática a múltiples idiomas.</li>
                <li>Sincronización automática a partir de transcripciones.</li>
                <li>
                  Conversión y segmentación de subtítulos en diferentes
                  formatos.
                </li>
              </ul>
            </CCol>
            <CCol sm="4"
              ><img
                src="@/assets/images/directo.png"
                alt="Sección eventos en directo"
              />
              <h2 style="margin-top: 20px; margin-bottom: 20px">
                Eventos en directo
              </h2>
              <ul style="text-align: left">
                <li>Subtitulación automática en directo.</li>
                <li>Transcripción automática temporizada.</li>
                <li>
                  Múltiples idiomas: español, catalán, inglés, francés, alemán,
                  italiano, portugués y muchos más.
                </li>
                <li>
                  Gestión de diferentes entradas y salidas para eventos
                  complejos o en varios idiomas simultáneamente.
                </li>
                <li>Planificación y administración de sesiones.</li>
              </ul></CCol
            >
          </CRow>
        </CContainer>
        <!--<CContainer fluid class="px-0">
          <img src="@/assets/images/separator.png" />
        </CContainer>-->
        <CContainer lg style="margin-top: 30px">
          <CRow style="">
            <CCol sm="6">
              <h2>Tecnología para la accesibilidad</h2>
              <p>
                <strong>COPLA</strong> es la
                <strong>Plataforma de Comunicación Accesible</strong>.
                Utilizamos las tecnologías del habla al servicio de la
                accesibilidad a la comunicación (subtitulado, traducción,
                audiodescripción, lengua de signos y mucho más):
              </p>
              <ul style="text-align: left">
                <li>Reconocimiento automático de voz.</li>
                <li>Procesamiento del lenguaje natural.</li>
                <li>Traducción automática.</li>
                <li>Sincronización automática.</li>
              </ul>
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeaderHome from '../components/AppHeaderHome.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    CContainer,
    AppHeaderHome,
  },
}
</script>
