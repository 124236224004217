<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>
<script setup></script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
a {
  text-decoration: none !important;
}
.sidebar-nav .nav-link {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.sidebar-nav .nav-title {
  font-size: 120%;
  border-top: 1px solid white;
}

h5 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #3b4054;
}

.message {
  font-size: 1.2em;
  margin-top: 25px;
}
</style>
