<template>
  <CHeader position="sticky" class="mb-4 header-inside">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" class="toggle" />
      </CHeaderToggler>
      <CHeaderBrand href="/sessions" class="mx-auto mx-md-0" to="/">
        <img
          src="@/assets/images/logo_claro.png"
          alt="Logo de Copla"
          style="height: 60px"
        />
      </CHeaderBrand>
      <CHeaderNav class="mx-auto mx-md-0">
        <CNavItem class="align-middle">
          <CNavLink>
            <CFormSelect v-model="rolSelected" v-if="roles.length > 0">
              <option
                v-for="rol in roles"
                :key="rol.id"
                :value="rol.id"
                :selected="rolSelected === rol.id.toString()"
              >
                {{ rol.name }}
              </option>
            </CFormSelect>
          </CNavLink>
        </CNavItem>
        <CNavItem class="align-middle">
          <AppHeaderDropdownAccnt />
        </CNavItem>
        <CNavItem class="align-middle">
          <CNavLink>
            <app-locale-changer />
          </CNavLink>
        </CNavItem>
        <CNavItem class="align-middle">
          <CButton @click="logoutUser()" color="danger">{{
            $t('TopMenu.Logout')
          }}</CButton>
        </CNavItem>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppLocaleChanger from './AppLocaleChanger'
export default {
  name: 'AppHeaderUnique',
  components: {
    AppHeaderDropdownAccnt,
    AppLocaleChanger,
  },
}
</script>

<script setup>
import { ref, onMounted, reactive, watch, defineEmits } from 'vue'
import { useStore } from 'vuex'
import useAuth from '../hooks/useAuth'
import useOrganization from '../hooks/useOrganization'
import router from '@/router'

let roles = reactive([])

let rolSelected = ref()

const store = useStore()
const baseURL = process.env.VUE_APP_BASE_URL
const minBaseURL = process.env.VUE_APP_MIN_BASE_URL
const organization = useOrganization(minBaseURL)

let auth0Client = ref()
let { buildAuth0Client, logout } = useAuth(baseURL)

const emits = defineEmits(['loaded'])

onMounted(async () => {
  if (localStorage.getItem('user') == undefined) {
    auth0Client.value = await buildAuth0Client()
    let userData = await auth0Client.value.getUser()
    store.commit('setUser', userData)
    localStorage.setItem('user', JSON.stringify(userData))
  } else {
    let userData = JSON.parse(localStorage.getItem('user'))
    store.commit('setUser', userData)
  }
  getRoles()
})

const getRoles = async () => {
  let response = await organization.getRoles()
  let rolDefault = false
  if (response.length > 0) {
    response.forEach((element, index) => {
      roles.push(element)
      if (element.last == 1) {
        rolSelected.value = element.id.toString()
        store.state.rol = element
        rolDefault = true
      }
      if (index == response.length - 1 && !rolDefault) {
        rolSelected.value = element.id.toString()
        store.state.rol = element
      }
    })
  } else {
    let semipath = router.currentRoute.value.fullPath.split('/')
    if (semipath[1] == 'live') {
      router.push('/' + semipath[1] + '/organization/new')
    } else {
      router.push('/organization/new')
    }
  }
}

watch(
  () => store.state.update,
  (newVal) => {
    if (newVal) {
      while (roles.length > 0) {
        roles.pop()
      }
      getRoles()
      store.commit('toogleUpdate')
    }
  },
)

watch(
  () => rolSelected.value,
  async (newVal) => {
    let response = await organization.updateLast(newVal)
    let roles = store.state.roles
    localStorage.setItem('activeRol', newVal)
    store.commit('setActiveRol', newVal)
    emits('loaded', true)
    router.push('/sessions')
  },
)

const logoutUser = async () => {
  auth0Client.value = await buildAuth0Client()
  await logout(auth0Client.value)
  localStorage.clear()
}
</script>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
ul {
  display: table;
}
li {
  display: table-cell;
}
.header {
  padding: 0 !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.header-inside {
  background-color: $copla-blue-grey-light;
}
.toggle {
  color: white;
}
</style>
