export default [
  {
    component: 'CNavItem',
    name: 'Home',
    to: '/sessions',
    icon: 'cil-home',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavTitle',
    name: 'Sessions',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'SessionList',
    to: '/sessions',
    icon: 'cil-grid',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'SessionVocabulary',
    to: '/sessions/vocabulary',
    icon: 'cil-speech',
    roles: ['Admin', 'Coordinator', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'SessionNew',
    to: '/sessions/new',
    icon: 'cib-addthis',
    roles: ['Admin', 'Coordinator'],
  },
  /*{
    component: 'CNavTitle',
    name: 'Billing',
    roles: ['Admin', 'Finances'],
  },
  {
    component: 'CNavItem',
    name: 'BillingData',
    to: '#',
    icon: 'cil-euro',
    roles: ['Admin', 'Finances'],
  },
  {
    component: 'CNavTitle',
    name: 'Tools',
    roles: ['Admin', 'Coordinator'],
  },
  {
    component: 'CNavItem',
    name: 'ToolsTranscription',
    to: '#',
    icon: 'cil-description',
    roles: ['Admin', 'Coordinator'],
  },*/
  {
    component: 'CNavTitle',
    name: 'Organization',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'OrganizationData',
    to: '/organization',
    icon: 'cil-grid',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'OrganizationMembers',
    to: '/organization/members',
    icon: 'cil-people',
    roles: ['Admin', 'Coordinator'],
  },
  {
    component: 'CNavItem',
    name: 'OrganizationNew',
    to: '/organization/new',
    icon: 'cib-addthis',
    roles: ['Admin', 'Coordinator', 'Writer', 'Finances', 'Producer'],
  },
  {
    component: 'CNavItem',
    name: 'OrganizationKey',
    to: '/organization/key',
    icon: 'cil-caret-right',
    roles: ['Admin', 'Coordinator', 'Producer'],
  },
]
