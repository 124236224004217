<template>
  <div>
    <AppSidebar type="Live" />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeaderUnique @loaded="headerLoaded" />
      <div style="min-height: 90vh" class="body flex-grow-1 px-3" v-if="load">
        <router-view />
      </div>
      <app-footer-inside />
    </div>
  </div>
</template>
<script>
import AppFooterInside from '@/components/AppFooterInside.vue'
import AppHeaderUnique from '@/components/AppHeaderUnique.vue'
import AppSidebar from '@/components/AppSidebar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeaderUnique,
    AppSidebar,
    AppFooterInside,
  },
}
</script>

<script setup>
import { ref } from 'vue'
let load = ref('false')
const headerLoaded = () => {
  load.value = true
}
</script>

<style lang="scss" scoped></style>
