import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import { createI18n } from 'vue-i18n'
import messages from './lang'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Datepicker from 'vue3-datepicker'

import 'flag-icons/css/flag-icons.min.css'

const i18n = createI18n({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true,
  messages,
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Datepicker', Datepicker)

app.config.globalProperties.axios = axios

app.mount('#app')
